import { useBreakpoint } from "use-breakpoint";
import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { Card, SEO, SiteLink } from "@parataxic/shared-ui";

import { default as Layout } from "../layouts/index";

const BREAKPOINTS = {
  base: 0,
  sm: "30em",
  md: "48em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
};

const SeriesItem: React.FC = ({ series }) => {
  return (
    <Card
      style={{
        // marginBottom:{{ base: "0.8rem", sm: 0 }}
        marginBottom: "0",
        textAlign: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      }}
      role="listitem"
    >
      <div style={{ textAlign: "center", width: "100%" }}>
        <SiteLink
          href={series.relativeUrl}
          style={{
            // fontSize={{ base: ".9rem", md: ".95rem", lg: "1rem" }}
            fontSize: "1rem",
            textAlign: "center",
          }}
        >
          <div
            style={{
              maxHeight: "15rem",
              maxWidth: "100%",
              textAlign: "center",
            }}
          >
            <GatsbyImage
              image={series.thumbnail.childImageSharp.gatsbyImageData}
              objectFit="cover"
              objectPosition="50% 50%"
              style={{
                margin: "0 auto",
                minWidth: "10rem",
              }}
              alt={series.name_long}
            />
          </div>
        </SiteLink>
      </div>

      <div style={{ textAlign: "center", width: "100%", padding: "1rem" }}>
        <SiteLink
          href={series.relativeUrl}
          style={{
            // fontSize={{ base: ".9rem", md: ".95rem", lg: "1rem" }}
            fontSize: "1rem",
          }}
        >
          {series.name_long}
        </SiteLink>
      </div>
      <div
        style={{
          textAlign: "center",
          width: "100%",
          fontSize: "0.85rem",
          color: "gray",
        }}
      >
        {series.vocabCount} items in {series.deckCount} decks
      </div>
    </Card>
  );
};

interface ISeriesViewProps {
  data: GatsbyTypes.SeriesListQuery;
}

const SeriesView: React.FC<ISeriesViewProps> = () => {
  const { series: seriesList } =
    useStaticQuery<GatsbyTypes.SeriesListerQuery>(graphql`
      query SeriesLister {
        series: allSeries {
          pageInfo {
            totalCount
          }
          edges {
            node {
              name_short
              name_long
              relativeUrl
              deckCount
              vocabCount
              thumbnail {
                childImageSharp {
                  gatsbyImageData(
                    height: 215
                    placeholder: TRACED_SVG
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
        }
      }
    `);
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "sm");

  return (
    <>
      <SEO
        title="Study Chinese Flashcards - Get your Zhongwen on!"
        description="Mobile-friendly flashcard site for Hànyǔ Shuǐpíng Kǎoshì, Practical Chinese Reader, Integrated Chinese, and more."
      />
      <div
        // SimpleGrid
        //  minChildWidth={["100%", "330px"]}
        //  spacing={[0, 6]}
        //  maxWidth="1000px"
        //  margin="0 auto"
        //  paddingX={{ base: 0, sm: "1rem" }}
        //  paddingBottom={{ base: "0.5rem", sm: "1rem" }}
        // _last={{ paddingBottom: 0 }}

        style={{
          display: "grid",
          gridTemplateColumns: ["base", "sm"].includes("breakpoint")
            ? "100%"
            : "repeat(auto-fit, minmax(330px, 1fr))", // 100% on mobile
          gridGap: ["base", "sm"].includes("breakpoint") ? 0 : "1rem",
          // gridGap: "var(--space-6)",
          maxWidth: "1000px",
          margin: "0 auto",
          padding: ["base", "sm"].includes("breakpoint")
            ? 0
            : "0rem 1rem 0 1rem",
          paddingInlineStart: ["base", "sm"].includes("breakpoint")
            ? 0
            : "1rem",
          paddingInlineEnd: ["base", "sm"].includes("breakpoint") ? 0 : "1rem",
          width: ["base", "sm"].includes("breakpoint") ? "50%" : "100%",
        }}
      >
        {seriesList.edges.map(({ node: series }, index) => (
          <SeriesItem series={series} key={index} />
        ))}
      </div>
    </>
  );
};

export default SeriesView;
